import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import "../styles/album.scss"
import SEO from "../components/seo-rando"
import AlbumImg from "../components/albumimg"

export default ({ data, pageContext }) => {
  const { albumname } = pageContext
  return (
    <Layout>
      <SEO
        title={albumname}
        description="Photo album"
        keywords={[
          `Trekathlon`,
          `Randonnée`,
          `Rando`,
          `Balade`,
          `Carte`,
          `Travel`,
        ]}
        meta={[{ name: "robots", content: "noindex" }]}
      />
      <section class="album">
        <h2>{albumname}</h2>
        <div class="group">
          {data.allImageSharp.edges.map(({ node, next, previous }) => (
            <div class="mypicture" key={node.id}>
              {node.fields.exif.meta.name}
              <AlbumImg
                filename={node.fields.exif.meta.location.base}
                next={next ? next.fields.exif.meta.location.base : ""}
                previous={
                  previous ? previous.fields.exif.meta.location.base : ""
                }
                alt={node.fields.exif.meta.title}
              />
            </div>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($albumname: String!) {
    allImageSharp(
      filter: { fields: { exif: { meta: { album: { eq: $albumname } } } } }
      sort: { fields: fixed___originalName }
    ) {
      edges {
        next {
          fields {
            exif {
              meta {
                location {
                  base
                }
              }
            }
          }
        }
        node {
          id
          fields {
            exif {
              raw {
                exif {
                  LensModel
                  Make
                  Copyright
                  Artist
                  ApertureValue
                  DateTimeOriginal
                  ExposureTime
                  FNumber
                  FocalLength
                  ISO
                  Orientation
                  ShutterSpeedValue
                  Model
                }
                image {
                  Artist
                  Make
                  ImageDescription
                }
              }
              gps {
                latitude
                longitude
              }
              meta {
                title
                album
                location {
                  base
                }
              }
            }
          }
        }
        previous {
          fields {
            exif {
              meta {
                location {
                  base
                }
              }
            }
          }
        }
      }
    }
  }
`
