import Img from "gatsby-image"
import { graphql, StaticQuery } from "gatsby"
import React from "react"
import style from "../styles/albumimg.module.scss"

const AlbumImg = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                sizes(
                  maxWidth: 1920
                  fit: CONTAIN
                  jpegProgressive: true
                  jpegQuality: 90
                  quality: 90
                ) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename)
      })
      //const anchor = "#" + props.filename
      //const next = "#" + props.next
      //const previous = "#" + props.previous
      if (!image) {
        return null
      }

      const imageSizes = image.node.childImageSharp.sizes
      return (
        <div class={style.imgbox}>
          <figure class="albumfig">
            <Img
              class={style.imgthumb}
              alt={props.alt}
              sizes={imageSizes}
              imgStyle={{
                objectFit: "contain",
                maxHeight: "80vh",
                boxShadow: "5 5 15 rgb(0 0 0)",
              }}
            />
            <figcaption>{props.alt}</figcaption>
          </figure>
        </div>
      )
    }}
  />
)

export default AlbumImg
